import request from "@/utils/request";

// 导出
// export function download(data, getToken) {
//     return request({
//         url: "/sys/orderTruing/download",
//         headers: {
//             Authorization: "Bearer " + getToken,
//             "Content-Type": "application/json",
//         },
//         method: "post",
//         data,
//     });
// }



export function queryOrderTruing(page, size, data) {
    return request({
        url: "/sys/orderTruing/queryOrderTruings?page=" + page + "&size=" + size,
        method: "post",
        data,
    });
}

export function addOrderTruing(data) {
    return request({
        url: "/sys/orderTruing/addOrderTruing",
        method: "post",
        data,
    });
}

export function getOrderTruing(id) {
    return request({
        url: "/sys/orderTruing/getOrderTruing/" + id,
        method: "get",
    });
}



export function updateOrderTruing(data) {
    return request({
        url: "/sys/orderTruing/updateOrderTruing",
        method: "post",
        data,
    });
}


export function deleteOrderTruing(data) {
    return request({
        url: "/sys/orderTruing/deleteOrderTruings",
        method: "post",
        data,
    });
}

export function setStatus(id, status) {
    return request({
        url: "/sys/orderTruing/setStatus?id=" + id + "&status=" + status,
        method: "post"
    });
}
