<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <!-- 搜索部分 -->
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">姓名</label>
        <el-input
          v-model="searchName"
          placeholder="请输入姓名"
          autocomplete="off"
          size="small"
          style="width: 120px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">身份证</label>
        <el-input
          v-model="searchIdNumber"
          placeholder="请输入身份证"
          autocomplete="off"
          size="small"
          style="width: 220px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">电话</label>
        <el-input
          v-model="searchMobileNumber"
          placeholder="请输入电话"
          autocomplete="off"
          size="small"
          style="width: 140px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="searchOrderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
          style="width: 220px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchSchoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
          style="width: 140px"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          v-model="searchCreateDate"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
        <el-button
          v-if="deleteButton"
          type="danger"
          icon="el-icon-delete"
          @click="handleDelete"
          size="small"
          >删除
        </el-button>
        <el-button
          type="warning"
          icon="el-icon-s-promotion"
          @click="handleExport"
          size="small"
          >导出
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="truingList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed type="selection" width="50px"></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          width="100px"
        ></el-table-column>
        <el-table-column
          prop="idNumber"
          label="身份证号"
          width="150px"
        ></el-table-column>
        <el-table-column
          prop="mobileNumber"
          label="手机号"
          width="120px"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="180px"
        ></el-table-column>
        <el-table-column
          prop="serviceType"
          label="服务类型"
          width="100px"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="serviceDescript"
          label="服务要求"
        ></el-table-column>
        <el-table-column
          prop="feePrice"
          label="实际收费"
          width="100px"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" width="80px" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 1"
              type="success"
              effect="plain"
              size="small"
              >预约
            </el-tag>
            <el-tag
              v-else-if="scope.row.status === 2"
              type=""
              effect="plain"
              size="small"
              >完成</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small"
              >取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="采集时间"
          width="220"
        >
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240px" align="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="viewButton"
              @click="handleView(scope.row.id)"
              >查看</el-button
            >
            <el-button
              v-if="downloadButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="
                downloadPhoto(
                  scope.row.pathCollectedPhoto,
                  scope.row.name,
                  scope.row.orderNo
                )
              "
              >下载照片
            </el-button>
            <el-button
              type="text"
              v-if="updateButton"
              v-show="scope.row.status === 1"
              @click="handleUpdate(scope.row.id)"
              >精修照片
            </el-button>
            <el-button
              v-if="cencelButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="handleCencel(scope.row.id)"
              >取消
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="dialogType === 1 ? '新增' : dialogType === 2 ? '查看' : '修改'"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form
        ref="truingForm"
        :model="truingForm"
        :rules="truingRules"
        label-width="80px"
      >
        <div class="tip">学生信息</div>
        <div class="">
          <el-row>
            <el-col :span="20">
              <el-row class="rowGray gray">
                <el-col class="cell" :span="4">
                  <label>姓名</label>
                </el-col>
                <el-col class="cell" :span="20">
                  <label v-text="truingForm.name"></label>
                </el-col>
              </el-row>
              <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>身份证号</label>
                </el-col>
                <el-col class="cell" :span="20">
                  <label v-text="truingForm.idNumber"></label>
                </el-col>
              </el-row>
              <el-row class="rowGray gray">
                <el-col class="cell" :span="4">
                  <label>手机号</label>
                </el-col>
                <el-col class="cell" :span="20">
                  <label v-text="truingForm.mobileNumber"></label>
                </el-col>
              </el-row>
              <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>订单号</label>
                </el-col>
                <el-col class="cell" :span="20">
                  <label v-text="truingForm.orderNo"></label>
                </el-col>
              </el-row>
              <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>服务要求</label>
                </el-col>
                <el-col class="cell" :span="20">
                  {{ truingForm.serviceDescript }}
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4" style="text-align: center">
              <el-image :src="photoImg" class="photo">
                <div slot="placeholder" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-col>
          </el-row>
        </div>
        <div class="tip">增值服务</div>
        <el-row :gutter="20">
          <el-col class="cell" :span="12">
            <el-form-item label="服务类型" prop="serviceType">
              <el-input
                type="text"
                v-model="truingForm.serviceType"
                placeholder="请输入服务类型"
                :disabled="dialogType === 2"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col class="cell" :span="12">
            <el-form-item label="实际收费" prop="feePrice">
              <el-input
                type="text"
                v-model="truingForm.feePrice"
                placeholder="请输入实际收费"
                :disabled="dialogType === 2"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="dialogType !== 2">
          <div class="tip">上传精修照片</div>
          <el-form-item label="文件：" prop="file">
            <el-upload
              class="upload-demo"
              ref="uploadImport"
              :multiple="false"
              action=""
              :limit="1"
              :on-change="handleChange"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              accept="image/jpeg,image/png"
              list-type="picture"
              :auto-upload="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件
              </el-button>
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                :loading="uploadLoading"
                @click="submitUpload"
                >上传
              </el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg,jpeg,png文件，尺寸为480X640
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="日志" prop="importMsg">
            <el-alert
              :visible.sync="msgVisible"
              v-model="importMsg"
              :title="importMsg"
              show-icon
              :type="importSatus"
            >
            </el-alert>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!--                <el-button-->
        <!--                        v-if="dialogType !== 2"-->
        <!--                        type="primary"-->
        <!--                        @click="handleConfirm"-->
        <!--                        :loading="confirmLoading"-->
        <!--                        style="margin-left: 77px"-->
        <!--                >保 存-->
        <!--                </el-button>-->
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleFinished"
          style="margin-left: 20px"
          >完 成
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryOrderTruing,
  getOrderTruing,
  updateOrderTruing,
  deleteOrderTruing,
  setStatus,
} from "@/api/truing";
import { reUploadPhoto } from "@/api/order";
import { getSchoolFiller } from "@/api/school";
import { queryPageButton } from "@/api/permission";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { dateFormat } from "@/utils/date";
export default {
  name: "OrderTruingMgt",
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;
    const validateFeePrice = (rule, value, callback) => {
      if (value != "0" && !value) {
        callback(new Error("实际收费不能为空"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确，只能输入数字"));
        } else {
          callback();
        }
      }
    };
    return {
      searchName: "",
      searchIdNumber: "",
      searchMobileNumber: "",
      searchOrderNo: "",
      searchSchoolId: "",
      truingList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchCreateDate: "",
      searchCreateDateEnd: "",
      dialogType: 1,
      dialogVisible: false,
      //学校
      schools: [],
      allSchools: [],
      loading: false,
      truingForm: { file: null, status: 1 },
      truingRules: {
        feePrice: [
          {
            required: true,
            trigger: "blur",
            validator: validateFeePrice,
          },
        ],
      },
      photoImg: "../temp/none_img.jpg",
      confirmLoading: false,
      //批量导入
      truingId: null,
      orderId: null,
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      uploadLoading: false,
      hasFile: false,
      fileList: [],

      //按钮权限
      searchButton: false,
      updateButton: false,
      deleteButton: false,
      viewButton: false,
      cencelButton: false,
      downloadButton: false,
      uploadButton: false,
      finishButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
  },
  methods: {
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    queryButton() {
      const data = 90;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "download") {
            this.downloadButton = true;
          }
          if (button.code === "upload") {
            this.uploadButton = true;
          }
          if (button.code === "finish") {
            this.finishButton = true;
          }
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableLoading = true;
      const data = {
        name: this.searchName,
        idNumber: this.searchIdNumber,
        mobileNumber: this.searchMobileNumber,
        orderNo: this.searchOrderNo,
        schoolId: this.searchSchoolId,
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
      };

      queryOrderTruing(this.currentPage, this.pageSize, data).then((resp) => {
        console.log(resp);
        const content = resp.data.content;
        this.truingList = content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    // 导出订单
    handleExport() {
      let url =
        // window._config["baseUrl"] +
        "/api/csias/sys/orderTruing/download";
      let params = [];
      if (this.searchSchoolId) {
        params.push(`schoolId=${this.searchSchoolId}`);
      }
      if (this.searchMobileNumber) {
        params.push(`mobileNumber=${this.searchMobileNumber}`);
      }
      if (this.searchName) {
        params.push(`name=${this.searchName}`);
      }
      if (this.searchIdNumber) {
        params.push(`idNumber=${this.searchIdNumber}`);
      }
      if (this.searchOrderNo) {
        params.push(`orderNo=${this.searchOrderNo}`);
      }
      if (this.searchCreateDate) {
        params.push(`stime=${this.searchCreateDate}`);
      }
      if (params.length) {
        url += "?" + params.join("&");
      }

      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
        timeout: 1000000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，打包文件不存在",
            });
          }
          this.confirmDownLoadingxp = false;
        },
        (err) => {
          this.confirmDownLoadingxp = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，打包文件不存在",
          });
        }
      );
    },

    resetSearch() {
      this.searchName = "";
      this.searchIdNumber = "";
      this.currentPage = 1;
      this.searchMobileNumber = "";
      this.searchOrderNo = "";
      this.searchSchoolId = "";
      this.searchCreateDate = "";
      this.searchCreateDateEnd = "";
      this.query();
    },
    handleConfirm() {
      if (this.dialogType === 3) {
        this.update();
      }
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的数据不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }

          deleteOrderTruing(data)
            .then((resp) => {
              if (resp.code === "0") {
                this.$notify({
                  title: "删除成功",
                  message: resp.message,
                  type: "success",
                });
                this.currentPage = 1;
                this.query();
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleView(id) {
      this.truingId = id;
      this.initPhoto();
      getOrderTruing(this.truingId)
        .then((resp) => {
          if (this.$refs.truingForm !== undefined) {
            this.$refs.truingForm.resetFields();
          }
          this.truingForm = resp.data;

          if (
            resp.data.pathCollectedPhoto == null ||
            resp.data.pathCollectedPhoto == ""
          ) {
            this.initPhoto();
          } else {
            this.getPhoto(resp.data.pathCollectedPhoto);
          }

          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdate(id) {
      this.truingId = id;
      this.initPhoto();
      getOrderTruing(this.truingId)
        .then((resp) => {
          if (this.$refs.truingForm !== undefined) {
            this.$refs.truingForm.resetFields();
          }
          this.truingForm = resp.data;
          this.orderId = resp.data.orderId;

          if (
            resp.data.pathCollectedPhoto == null ||
            resp.data.pathCollectedPhoto == ""
          ) {
            this.initPhoto();
          } else {
            this.getPhoto(resp.data.pathCollectedPhoto);
          }

          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    initPhoto() {
      this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
    },
    refreshPhoto(id) {
      getOrderTruing(id)
        .then((resp) => {
          if (
            resp.data.pathCollectedPhoto == null ||
            resp.data.pathCollectedPhoto == ""
          ) {
            this.initPhoto();
          } else {
            this.getPhoto(resp.data.pathCollectedPhoto);
          }
        })
        .catch((error) => {});
    },
    update() {
      this.$refs.truingForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.truingForm;
          updateOrderTruing(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    downloadPhoto(fileid, name, orderNo) {
      if (fileid == null || fileid == "") {
        this.$notify.error({
          title: "错误",
          message: "该订单尚未上传照片！",
        });
        return;
      }
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "arraybuffer",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            let blob = new Blob([imgContent], {
              type: "application/octet-stream",
            });
            let fileName = name + "-" + orderNo + ".jpg";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载照片成功！",
            });
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    //上传学生照片
    submitUpload() {
      this.uploadLoading = true;
      //this.$refs.upload.submit();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles(); // 清除上传的文件
      }
      this.fileList = [];

      let dataPar = this.truingForm;
      // const file = dataPar.file;
      // const size = file.size / 1024;
      // if (size > 400) {
      //     this.$notify.warning({
      //         title: "警告",
      //         message: "大小必须小于400K",
      //     });
      //     return;
      // }

      let fdParams = new FormData();
      fdParams.append("id", this.orderId);
      fdParams.append("file", dataPar.file.raw);
      reUploadPhoto(fdParams).then((resp) => {
        if (resp.data.code == 0) {
          this.importMsg = resp.data.msg;
          this.importSatus = "success";
          this.$notify({
            title: "上传照片成功！",
            message: resp.message,
            type: "success",
          });
          this.refreshPhoto(this.truingId);
        } else {
          this.importMsg = resp.data.msg;
          this.importSatus = "error";
        }
        this.uploadLoading = false;
        this.msgVisible = false;
        this.query();
      });
    },
    handleFinished() {
      this.$confirm("是否确认保存信息并完成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs.truingForm.validate((valid) => {
            if (valid) {
              this.confirmLoading = true;
              this.truingForm.status = 2;
              const data = this.truingForm;
              updateOrderTruing(data)
                .then((resp) => {
                  this.$notify({
                    title: "保存并完成订单成功",
                    message: resp.message,
                    type: "success",
                  });
                  this.confirmLoading = false;
                  this.dialogVisible = false;
                  this.currentPage = 1;
                  this.query();
                })
                .catch((error) => {
                  this.confirmLoading = false;
                });
            } else {
              return false;
            }
          });
        })
        .catch(() => {});
    },
    handleCencel(id) {
      this.$confirm("是否确认取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.finishedLoading = true;
          setStatus(id, 3)
            .then((resp) => {
              this.$notify({
                title: "取消订单成功",
                message: resp.message,
                type: "success",
              });
              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.truingForm.file = null;
    },
    handlePreview(file) {
      //预览
      console.log(file);
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.truingForm.file = file;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
  },
};
</script>

<style>
.divForm {
  margin-top: 15px;
}

.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 35px;
  line-height: 30px;
  vertical-align: bottom;
  overflow-y: auto;
}

.gray {
  background-color: #f2f2f2;
}

.device {
  width: 1200px;
  height: 180px;
}

.device .col-1eft {
  float: left;
  width: 900px;
  height: 176px;
}

.device .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}

.photo {
  width: 120px;
  height: 160px;
}
</style>
